<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="row">
                                            <div class="col-6">
                                                <v-text-field class="w-50" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                            <div class="col-6 text-right">
                                                <v-btn class="mt-3 mr-3" color="primary" elevation="2" small rounded @click="showDialogCreateMpf()">Create MPF<v-icon right dark>mdi-file-plus</v-icon></v-btn>
                                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="populateMpfList()">Refresh<v-icon right dark>mdi-autorenew</v-icon></v-btn>
                                            </div>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.actions`]="{ item }">
                                    <div v-if="item.stat == 'S'" class="d-flex justify-content-end">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <!-- <v-btn class="" color="cyan" elevation="2" small dense rounded @click="showItemDetails(item)" dark>Details</v-btn> -->
                                    </div>
                                    <div v-else-if="item.stat == 'D'" class="d-flex justify-content-end">
                                        <v-chip color="green" class="mt-1 mr-3" small dense dark>APPROVED</v-chip>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    <div v-else-if="item.stat == 'X'" class="d-flex justify-content-end">
                                        <v-chip color="red" class="mt-1 mr-3" small dense dark>REJECTED</v-chip>
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item link>
                                                    <v-list-item-title class="ma-2" @click="printMPF(item)">Print</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

            <v-dialog v-model="dialogCreateMpf" max-width="1024px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title">Create MPF</h5>
                        <div class="row">
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfCreateEntityModel" :items="mpfCreateEntityLists" item-value="entity_id" item-text="description" label="To Entity" @change="(event) => entityOnChange(event)" clearable dense prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfCreateCategoryModel" :items="mpfCreateCategoryLists" :loading="categoryLoading" item-value="cat_id" item-text="cat_desc" label="Category" @change="(event) => categoryOnChange(event)" no-data-text="Please Select Entity !" clearable dense prepend-icon="mdi-checkbox-multiple-blank" return-object></v-select>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-text-field v-model="mpfCreateOtherCategoryModel" v-show="showCreateOthercategory" label="Other Category" dense prepend-icon="mdi-checkbox-multiple-blank"></v-text-field>
                            </div>
                            <div class="col-lg-6 col-6">
                                <v-select v-model="mpfCreateReceiverModel" :items="mpfCreateReceiverLists" :loading="receiverLoading" item-value="group_id" item-text="deskripsi" label="Receiver Group" no-data-text="Please Select Entity !" clearable dense prepend-icon="mdi-account-multiple"></v-select>
                            </div>
                            <div class="col-lg-6 col-12">
                                <v-text-field v-model="mpfCreateOrderNoModel" label="Order No." dense prepend-icon="mdi-content-paste"></v-text-field>
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-textarea outlined v-model="mpfCreateRemarkModel" counter label="MPF Remark"></v-textarea>
                            </div>
                            <div class="col-lg-12 col-12">
                                <input type="file" name="fileImage" ref="fileImage" id="fileImage" @change="mpfCreatefileChange" class="" accept="image/*" />
                            </div>
                            <div class="col-lg-12 col-12 text-right">
                                <v-btn class="mt-3 mr-3" color="red" elevation="2" small rounded @click="dialogCreateMpf = false" dark>Cancel</v-btn>
                                <v-btn class="mt-3" color="success" :disabled='submitBtnDisable' elevation="2" small rounded @click="submitNewMPF()">{{ submitBtnLabel }}</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogDetailMpf" max-width="720px" >
            
                <div class="card elevation-5">
                    <!-- <div class="card-header bg-transparent border-bottom-2 text-right">
                        <v-btn class="" color="red" elevation="2" small @click="dialogDetailMpf = false"><v-icon color="white">mdi-close</v-icon></v-btn>
                    </div> -->
                    <div class="card-body">
                        <h5 class="card-title">Detail MPF</h5>
                        <h5 class="card-title">Attachment : <v-chip color="blue" v-if="imageDetail" @click="showImage()" small dense dark> Click for view attachment </v-chip> <v-chip color="red" v-else small dense dark> No Attachment </v-chip> </h5>
                        <h5 class="card-title">Order ID : <v-chip color="blue" v-if="mpfOrderId" @click="showOrderDetail()" small dense dark> {{ mpfOrderId }} </v-chip> <v-chip color="red" v-else small dense dark> No Order ID </v-chip> </h5>
                        <h5 class="card-title">Remark : </h5>
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <ul>
                                    <li v-for="item in mpfDetailsList" :key="item.seq_no">
                                        <span>{{ item.formattedDate }} | {{ item.created_by.toUpperCase() }} : {{ item.message_body }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="mpfStat != 'X'" class="col-lg-12 col-12 text-right">
                                <v-textarea class="pt-5" v-model="addCommentModel" counter label="Add comment here..." dense></v-textarea>
                                <v-btn class="" color="success" elevation="2" :disabled='addCommentBtnDisable' small @click="addComment()" dense>{{ addCommentBtnStr }}</v-btn>
                            </div>
                            <div v-if="mpfStat == 'S' && isApprove1 == true" class="col-lg-12 col-12 d-flex justify-content-end">
                                <v-autocomplete v-model="ccModel" :items="ccList" item-value="group_id" item-text="deskripsi" outlined dense chips small-chips label="CC Recipients" multiple ></v-autocomplete>
                                <v-btn class="mt-3 ml-3 mr-3" color="success" elevation="2" small rounded @click="approveMPF()">Approve</v-btn>
                                <v-btn class="mt-3" color="red" elevation="2" small rounded @click="rejectMPF()" dark>Reject</v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogDetailOrder" max-width="1280px" >
            
                <div class="card elevation-5">
                    <div class="card-body">
                        <!-- <h5 class="card-title">Detail Order ID</h5> -->
                        <div class="row">
                            <div class="col-lg-6 col-6">
                                Customer ID : {{ customerIDDetail }}
                            </div>
                            <div class="col-lg-6 col-6">
                                Customer Name : {{ customerNameDetail }}
                            </div>
                            <div class="col-lg-6 col-6">
                                Customer Address : {{ customerAddressDetail }}
                            </div>
                            <div class="col-lg-6 col-6">
                                Customer City : {{ customerCityDetail }}
                            </div>
                            <div class="col-lg-12 col-12">
                                <v-data-table :headers="ItemDetailHeaders" :items="ItemDetailItemLists" class="elevation-1" page-count="5" :loading="ItemDetailLoadingDatatable" dense></v-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            
            </v-dialog>

            <v-dialog v-model="dialogShowImage" max-width="768px" >
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-img :src = imgUrl ></v-img>
                    </div>
                </div>
            </v-dialog>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Viva',
                disabled: false,
                href: '/admin/viva',
                },
                {
                text: 'MPF',
                disabled: false,
                href: '#',
                },
                {
                text: 'MPF Inbox',
                disabled: true,
                href: '#',
                },
            ],
            headers: [
                { text: 'ID', value: 'tr_id', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'FROM', value: 'fromStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'TO', value: 'toStr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'SUBJECT', value: 'cat_desc', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'DATE', value: 'created_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'APPROVE 1', value: 'approve1_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: 'APPROVE 2', value: 'approve2_date', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                { text: '', value: 'actions', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
            ],
            itemLists: [],
            searchItem: '',
            loadingDatatable: false,
            dialogCreateMpf: false,
            mpfCreateEntityModel: '',
            mpfCreateEntityLists: [],
            mpfCreateCategoryModel: '',
            mpfCreateCategoryLists: [],
            mpfCreateOtherCategoryModel: '',
            showCreateOthercategory: false,
            mpfCreateReceiverModel: '',
            mpfCreateReceiverLists: [],
            mpfCreateOrderNoModel: '',
            mpfCreateRemarkModel: '',
            mpfCreateimageModel: '',
            dialogDetailMpf: false,
            imageDetail: '',
            mpfDetailsList: [],
            addCommentModel: '',
            ccModel: '',
            ccList: [],
            mpfStat: '',
            imgUrl: '',
            isApprove1: false,
            dialogShowImage: false,
            categoryLoading: false,
            receiverLoading: false,
            submitBtnDisable: false,
            submitBtnLabel: 'Submit',
            addCommentBtnDisable: false,
            addCommentBtnStr: 'Add Comment',
            mpfOrderId: '',
            dialogDetailOrder: false,
            ItemDetailHeaders: [],
            ItemDetailItemLists: [],
            ItemDetailLoadingDatatable: false,
            customerIDDetail: '',
            customerNameDetail: '',
            customerAddressDetail: '',
            customerCityDetail: '',
            entityDetailItem: ''
        }
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods:{
        
        async initialize(){
            
            this.populateMpfList()
            // this.addCommentModel = ''

        },

        async populateMpfList(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/getData`, { 



            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false

                if(res.data.mpf_group != 'false'){

                    if(res.data.isApprove == '1'){
                        this.isApprove1 = true
                    }

                    // console.log(res.data.isApprove);

                    this.mpfCreateEntityLists = res.data.entity
                    this.itemLists = res.data.mpfList

                } else {

                    Swal.fire({
                        title: 'Access Denied',
                        text: "You won't be able to create MPF, please call IT Administrator!",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then((result) => {
                        this.$router.push('/admin/viva/dashboard');
                    })

                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async submitNewMPF(){

            if(this.mpfCreateEntityModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Entity !'
                })

                return false

            }

            if(this.mpfCreateCategoryModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Category !'
                })

                return false

            }

            if(this.mpfCreateReceiverModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please select Receiver !'
                })

                return false

            }

            if(this.mpfCreateRemarkModel == ''){

                Swal.fire({
                    icon: 'warning',
                    text: 'Please fill remark !'
                })

                return false

            }

            this.submitBtnDisable = true
            this.submitBtnLabel = 'Loading...'

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/submitNewMPF`, { 

                    entity: this.mpfCreateEntityModel ? this.mpfCreateEntityModel : '',
                    category: this.mpfCreateCategoryModel.cat_id ? this.mpfCreateCategoryModel.cat_id : '',
                    otherCategory: this.mpfCreateOtherCategoryModel ? this.mpfCreateOtherCategoryModel : '',
                    receiver: this.mpfCreateReceiverModel ? this.mpfCreateReceiverModel : '',
                    orderId: this.mpfCreateOrderNoModel ? this.mpfCreateOrderNoModel : '',
                    remark: this.mpfCreateRemarkModel ? this.mpfCreateRemarkModel : '',
                    image: this.mpfCreateimageModel ? this.mpfCreateimageModel : '',

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                if(res.data.result == 'OK'){

                    this.submitBtnDisable = false
                    this.submitBtnLabel = 'Submit'

                    Swal.fire({
                        icon: 'success',
                        text: 'MPF Created successfully, go to Outbox for approve before send the MPF'
                    })

                    this.dialogCreateMpf = false

                } else {

                    this.submitBtnDisable = false
                    this.submitBtnLabel = 'Submit'

                    Swal.fire({
                        icon: 'warning',
                        text: 'Oopss.. there is something wrong, call IT Administrator'
                    })

                    this.dialogCreateMpf = false
                    this.populateMpfList()

                }

                

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        showDialogCreateMpf(){

            this.mpfCreateEntityModel = ''
            this.mpfCreateCategoryModel = ''
            this.mpfCreateOtherCategoryModel = ''
            this.mpfCreateReceiverModel = ''
            this.mpfCreateOrderNoModel = ''
            this.mpfCreateRemarkModel = ''
            this.mpfCreateimageModel = ''
            // this.$refs.fileImage = null
            $('#fileImage').val('')

            this.dialogCreateMpf = true

        },

        async showItemDetails(id){

            this.$store.dispatch('setOverlay', true)

            this.mpfOrderId = id.order_id
            this.mpfDetailTrId = id.tr_id
            this.entityDetailItem = id.entity_id

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/getDetails?id=${id.tr_id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.mpfStat = id.stat
                this.dialogDetailMpf = true
                this.mpfDetailsList = res.data.result
                this.imageDetail = res.data.image
                this.ccList = res.data.group_cc

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async entityOnChange(id){

            this.mpfCreateCategoryModel = ''
            this.mpfCreateCategoryLists = [],
            this.mpfCreateOtherCategoryModel = ''
            this.mpfCreateReceiverModel = ''
            this.mpfCreateReceiverLists = []
            this.mpfCreateOrderNoModel = ''
            this.mpfCreateRemarkModel = ''

            this.categoryLoading = true
            this.receiverLoading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/getCategoryByEntity?entity=${id}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.mpfCreateCategoryLists = res.data.category
                this.mpfCreateReceiverLists = res.data.receiver

                this.categoryLoading = false
                this.receiverLoading = false

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        categoryOnChange(id){

            if(id.cat_desc == 'OTHER'){
                this.showCreateOthercategory = true
            } else {
                this.showCreateOthercategory = false
            }

        },

        showImage(){

            this.imgUrl = process.env.VUE_APP_URL + this.imageDetail
            this.dialogShowImage = true

        },

        async addComment(){

            if(this.addCommentModel){

                this.addCommentBtnDisable = true
                this.addCommentBtnStr = 'Loading...'

                await axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/addCommentMPF`, {

                    id: this.mpfDetailTrId ? this.mpfDetailTrId : '',
                    remark: this.addCommentModel ? this.addCommentModel : '',

                },
                { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.addCommentBtnDisable = false
                    this.addCommentBtnStr = 'Add Comment'

                    this.mpfDetailsList = []
                    this.mpfDetailsList = res.data.result
                    this.addCommentModel = ''
                    
                })
                .catch(err => {
                    
                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401') {
                        this.$router.push('/');
                    } else {
                        Swal.fire({
                            text: err.response.data.data,
                            icon: 'error',
                        })
                    }

                })

            }

        },

        approveMPF(){

            Swal.fire({
                text: 'Do you want Approve this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                denyButtonText: 'Cancel',
                denyButtonColor: 'red'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/approveMPF`, {

                        id: this.mpfDetailTrId ? this.mpfDetailTrId : '',
                        cc: this.ccModel ? this.ccModel : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.dialogDetailMpf = false
                        this.populateMpfList()
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        rejectMPF(){

            Swal.fire({
                text: 'Do you want Reject this MPF ?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                denyButtonText: 'Cancel',
                denyButtonColor: 'red'
                }).then((result) => {
                if (result.isConfirmed) {
                    
                    axios.post(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/rejectMPF`, {

                        id: this.mpfDetailTrId ? this.mpfDetailTrId : '',

                    },
                    { 
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                    })
                    .then(res => {

                        this.dialogDetailMpf = false
                        this.populateMpfList()
                        
                    })
                    .catch(err => {
                        
                        this.$store.dispatch('setOverlay', false)
                        if(err.response.status == '401') {
                            this.$router.push('/');
                        } else {
                            Swal.fire({
                                text: err.response.data.data,
                                icon: 'error',
                            })
                        }

                    })

                }
            })

        },

        mpfCreatefileChange(e){

            let file = e.target.files[0]
            this.mpfCreateimageModel = ''

            if(file){

                if (!file.type.includes('image/')) {
                    swal("Error", "Please select an image file!", "error");
                    return
                }
                // if (file.size > 10000000) {
                //     swal("Failed", "File image maximum 10 MB!", "error");
                //     return
                // }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                        this.mpfCreateimageModel = event.target.result
                    }
                    reader.readAsDataURL(file)
                } else {
                    swal("Error", "Sorry, FileReader API not supported", "error");
                }

            }

        },

        printMPF(id){

            this.$router.push(`/printMPF/${id.tr_id}`);

        },

        async showOrderDetail(){

            console.log(this.entityDetailItem);

            this.dialogDetailOrder = true
            this.ItemDetailHeaders = []

            switch (this.entityDetailItem) {
                case 'KKA':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'THICKNESS.', value: 'Tebal', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'LENGTH', value: 'Panjang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'QTY', value: 'Lembar', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                case 'KMB':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'THICKNESS.', value: 'Tebal', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'LENGTH', value: 'Panjang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'QTY', value: 'Lembar', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                case 'MMM':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'THICKNESS.', value: 'Tebal', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'LENGTH', value: 'Panjang', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'QTY', value: 'Lembar', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                case 'KBT':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'description', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'QTY', value: 'tr_qty', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                case 'SM':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'descr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'WGT.(KG)', value: 'wgt_ord', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                case 'SR':
                    this.ItemDetailHeaders = [
                        { text: 'NO.', value: 'sequence', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'PRODUCT', value: 'descr', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                        { text: 'WGT.(KG)', value: 'wgt_ord', align: 'left', class: 'primary--text blue lighten-5', sortable:false },
                    ]
                    break;
                default:
                    console.log('I do not recognize this.');
            }

            this.ItemDetailItemLists = []
            this.customerIDDetail = ''
            this.customerNameDetail = ''
            this.customerAddressDetail = ''
            this.customerCityDetail = ''
            this.ItemDetailLoadingDatatable = true

            await axios.get(`${process.env.VUE_APP_URL}/api/viva/MPFInbox/getProductDetail?id=${this.mpfDetailTrId}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.customerIDDetail = res.data.customer['CustomerId']
                this.customerNameDetail = res.data.customer['NamaCustomer']
                this.customerAddressDetail = res.data.customer['Alamat']
                this.customerCityDetail = res.data.customer['Kota']

                this.ItemDetailLoadingDatatable = false
                this.ItemDetailItemLists = res.data.result

            })
            .catch(err => {
                
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        }

    },
    watch: {

        
    }
}

</script>

<style scoped>
    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>